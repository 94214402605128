let awsRegion = 'us-east-2';

export default {
 api_urls: {
   reporting: 'https://nsazqood4c.execute-api.us-east-2.amazonaws.com/api',
   geojson: 'https://dp8j670as0.execute-api.us-east-2.amazonaws.com/api',
   overlays: 'https://72m6gmrrnk.execute-api.us-east-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.vale.forwoodsafety.com',
 forwood_id_url: 'https://id.vale.forwoodsafety.com?redirect_uri=https://geoeditor.vale.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.vale.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-east-2_WBDx22fhW',
   userPoolWebClientId: 's73gbdmf623acsqo0mi5pdcf8',
   cookieStorage: {
     domain: '.vale.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
